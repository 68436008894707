<template>
  <div class="rule">
    <ShopNavBar title="规则"></ShopNavBar>

    <img src="@/assets/img/shop/prize-rule.png" />
  </div>
</template>

<script>
import { NavBar } from 'vant';

export default {
  components: {
    [NavBar.name]: NavBar
  },
  setup() {
    return {
    }
  },
  methods: {
    onBack() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.rule {
  background-color: #fff;
  min-height: 100vh;
}

.rule img {
  width: 100%;
  display: block;
}
</style>